@import '../../../App';

.private-chat-main {
    min-height: 500px;
    max-height: 900px;
    overflow: scroll;
    @media (max-width: mediaWight(desktopSm)) {
        min-height: 68vh;

    }
}
.private-chat-card{
    display: flex;
    gap: 14px;
    padding: 10px;
    &:hover{
        cursor: pointer;
    }
}

.private-chat-detail {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 4px;
}
.private-chat-name{
    @include flex(space-between,center);
    .name_wrapper {
        display: flex;
        gap: 8px;

    }
    .name{  
        font-family: UniversLTPro-45Light, Univers, univers, Helvetica, helvetica, Arial, arial, 黑体, "Hei Ti", heiti, 微软雅黑, "Microsoft YaHei", 宋体, SimSun, sans-serif !important;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000000DE
    }
    .time{
        @include font-style($font-main, 400, 12px, 14px);
        color: $time-color;
    }
}
.private-chat-msg{
    @include flex(space-between,center);
    .msg{ color: $color-silver;}
    .msg-numbers{
        background-color: $color-two;
        color: $color-three;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 999px;
        width: 25px;
        height: 25px;
        font-size: 12px;
        padding-left: 1px;
    }
}