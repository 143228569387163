@import "../../App";

.participants-main {
  display: flex;
  background-color: rgb(233, 231, 231);
  transition: all ease-in-out;
  transition-duration: 200;
  height: 100%;
  animation: slide 2s forwards;

  .MuiPaper-root {
    width: 100%;
  }
}

.participants-main::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa;
  /* or add it to the track */
}

@keyframes slide {
  100% {
    left: 0;
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

.participants-inside {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
}

.title-main {
  //padding: 24px 20px;
  ////display: flex-end;
  //background-color: $color-background-container;
  //color: $child-titleTextColor;

  .title {
    width: 100%;
    text-align: center;
    @include flex_nojustify(center);
    @include font-style($font-main, 800, 24px, 28px);
  }
}

.search {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 12px 8px;
  gap: 8px;

}

.participants-filter {
  display: flex;
  padding: 9px;
  position: relative;
  background: $bgColor-filter-participant;
  border-radius: 999px;

  &:hover {
    cursor: pointer;
  }
}

.fullScreen-icon {
  width: 40px;
  padding: 2px;
  margin-top: 3px;
  background: $bgColor-filter-full-screen;
  border-radius: 50%;

}

.fullScreen-icon:hover {
  cursor: pointer;
}

.participants-cards-main {
  @include flex(content, center);
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  overflow: auto;
  padding: 0 16px;

  position: relative;
}
.participant__loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $color-white;
  z-index: 2;
  .circularProgress{
    margin: 50% !important;
  }
}

.participants-card {
  padding: 8px 0;
  @include flex(space-between, center);
  & >div >.avatar-participants{
    margin-right: 16px;

  }
  .participants-dp{
    margin-right: 8px;
  }
  div:first-child {
    display: flex;
  }

  .participants-detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 3px;

    .user-name {
      gap: 8px;
      @include flex(start,center );
      @include font-style($font-main, 500, 16px, 20px);
      .presence-main{
        gap: 0;
      }
    }

    .user-email {
      @include font-style($font-title, 400, 14px, 16px);

      color: $color-email-participant-cart;
    }
    .user__full-tile {

      color: $color-silver;
    }
  }

  svg {
    width: 20px;
  }

  img {
    border-radius: 999px;
    width: 48px;
    height: 48px;
  }
}

.filter-menu-head {
  text-align: center;
  padding: 8px 36px;
  margin: 4px 4px;
  background: rgb(231, 231, 231);
  @include font-style($font-main, 500, 16px, 24px);
}

.filter-menu {
  padding: 8px 36px;
  margin: 4px 4px;
  @include font-style($font-main, 400, 16px, 24px);
  cursor: pointer;
  @include flex(space-between, center);

  svg {
    color: $color-filter-menu-check-icon;
  }

}

.filter-menu:hover {
  background: $color-email-participant-cart;
  color: $color-hover-filter-menu;
  transition: 0.5s;
}
.people-count-participant{
  margin-right: 72px;
  color: $color-grey-shade;
  @include font-style($font-third,300,18px,21px);
}
.participant-profile-tag {
  display: flex;
  width: 95%;
  margin: auto;
  padding: 0 20px;
  gap: 10px;
  overflow: hidden;

  .tag {
    padding: 7px 12px;
    border-radius: 99px;
    @include flex(center, center);
    gap: 2px;
    background-color: rgb(243, 244, 248);
    border: 1px solid lightgrey;
  }
}

@media (min-width: 1024px) and (max-width: 1680px) {

  .participants-filter {
    padding: 6px;
  }
  .fullScreen-icon {
    height: 26px;
    width: 26px;
  }

  .filter-menu-head {
    padding: 4px 32px;
    @include font-style($font-main, 500, 14px, 24px);
  }

  .filter-menu {
    padding: 4px 32px;
    @include font-style($font-main, 400, 14px, 24px);
  }

  .participants-main {
    animation: slide 2s forwards;

    .MuiPaper-root {
      width: 100%;
    }
  }


  .title-main {
    .title {
      @include font-style($font-second, 700, 20px, 26px);
    }
  }

  .search {

    svg {
      width: 20px;
      height: 20px;
    }


  }

  .participants-cards-main {
    padding: 0 8px;
    gap: 10px;
  }

  .participants-card {
    padding: 0 0;

    .participants-detail {
      gap: 4px;

      .user-name {
        @include font-style($font-main, 500, 16px, 19px);
      }

      .user-email {
        @include font-style($font-main, 400, 12px, 16px);
      }
    }

    svg {
      width: 16px;
    }

  }
}

@media only screen and (min-width: 425px) and (max-width: 1024px) {
  .fullScreen-icon {
    display: none;
  }
  .participants-cards-main {
    height: auto;
    min-height: 600px;
    max-height: 900px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .participants-main {
    height: 90vh ;
    width: 100%;

    .title-main {
      display: none;
    }
  }
  .fullScreen-icon {
    display: none;
  }

  .search {
    padding: 12px 12px 12px 12px;

  }

  .participants-cards-main {
    padding: 0 14px;
    gap: 4px;
  }

  .desc-container {
    padding: 0;
  }
}
.participant-title-table {
  color: black;
  margin:  0 24px 0 15px;
  @include font-style("Helvetica Neue", 500, 24px, 30px);
}

.table-participant-full-name-presence-container {
  .table-participant-full-name {
    display: flex;
    @include font-style($font-third, fontWeight(meduim), 16px, 19px);

    flex-direction: column;
    gap: 15px;
    color: $color-name;
    .table-participant-country{
     span{
       margin-right: 10px;
     }
    }
    span {
      color: $color-grey-shade
    }
  }

  display: flex;
  gap: 8px;
}
.table-participant-job{
  span{
    color: rgba(40, 40, 40, 0.5);
  }
}
.table-participant-presence{
  align-self: flex-start;
}
.online-circle {
  background: $color-status-online;
}

.offline-circle {
  background: $color-status-offline;
}
.participants-helper-mobile{
  margin-top: 6px;
  @media (min-width: mediaWight(mobileXl)) {
    display: none;
  }
}
.offline-circle,
.online-circle {

  box-shadow: 0 0 0 3px $bgColor-status-online;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  bottom: 0;
}

.pointer:hover {
  cursor: pointer;
}

