@import './Colors.scss';
@import './assets/scss/variables';
@import './assets/scss/function';

@mixin more-text($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin p-for-btn-mui {
  padding: 19px 32px;
}

// colors
@mixin banner-h-color {
  color: $color-white;
}

@mixin main-h-color {
  color: $color-black;
}

@mixin para-color {
  color: $color-contrast;
}

@mixin tag-color {
  color: #000000;
}

@mixin flex($property1, $property2, $flexDirection: row) {
  display: flex;
  justify-content: $property1;
  align-items: $property2;
  flex-direction: $flexDirection;
}

@mixin flex_nojustify($propert1) {
  display: flex;
  //justify-content: $propert1;
  align-items: $propert1;
}

@mixin font-style($font, $weight, $size, $lineHeight) {
  font-family: $font !important;
  font-weight: $weight;
  font-size: $size;
  line-height: $lineHeight;
}

@mixin title {
  /* gradient */
  background: $color-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin button-hover {
  /* gradient */
  background: $color-hover;
  color: $color-three !important;
  transition: ease-in-out;
  transition-duration: 400ms;
  cursor: pointer;
}

@mixin button {
  background: $color-gradient;
  width: 100%;
  color: $color-white !important;
  font-size: 15px;
  &:hover{
    @include button-hover;
  }
}

/* Container */

@mixin container {
  width: 100%;

  @media (min-width: 640px) {
    max-width: 640px;
    margin: 0 auto;
  }
  /* md*/
  @media (min-width: 768px) {
    max-width: 768px;
  }
  /* lg*/
  @media (min-width: 1024px) {
    max-width: 1024px;
  }
  /* xl*/
  @media (min-width: 1280px) {
    max-width: 1280px;
  }
  /* xl*/
  @media (min-width: 1530px) {
    max-width: 1530px;
  }
}

.sign-in-main,
.edit-profile-wraper,
.forgot-pass-form {
  .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
  }
  .MuiInputBase-root {
    border: 1px solid rgba(0, 0, 0, 0.26) !important;
    height: 100%;
  }
}

.search {
  @media screen and (min-width: 1024px) and (max-width: 1771px) {
    fieldset {
      top: -14px;
      // height: 114%;
    }
  }
}
