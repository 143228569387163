@import "../../App";

.attachments {

  &__header {
    padding: 23px 24px;
    height: 76px;
    box-sizing: border-box;
    background-color: #F8F8F8;
    color: #eb3c24;

    display: flex;
    gap: 24px;
    align-items: center;

    & > p {
      font-family: UniversLTPro-65Bold, Univers, univers, Helvetica, helvetica, Arial, arial, 黑体, "Hei Ti", heiti, 微软雅黑, "Microsoft YaHei", 宋体, SimSun, sans-serif !important;

      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
    }

    & > img {
      cursor: pointer;
    }
  }

  &__main {
    padding: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
}

.handout-container {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  width: 80%;

  .handout-header {
    font-size: 1.2em;
    margin-bottom: 15px;
    font-weight: 700;
  }

  .handout-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .filename-container {
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      align-items: center;
      width: 100%;

      .filename-input {
        border: none;
        padding: 10px;
        flex-grow: 1;
        background-color: #f9f9f9;
        font-size: 14px;

        &:focus {
          outline: none;
        }
      }
    }

    .download-button {
      width: 100%;
      height: 40px;

      background-color: #000;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #333;
      }
    }
  }
}
