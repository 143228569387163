// TODO: #4C6FFF
// TODO: #A1A9BF
// TODO: #F5F5F5
// TODO: #545E67
// TODO: #6B6F7A
// TODO: #384150

$color_one: #eb3c24;
$color_two: #000000;
$color_three: #ffffff;
$color_four: #afafaf;
$color_five: #515242;
$color_six: #1a1003;
$color-seven: #ffffff;

$primary-one: $color-one;
$primary-two: $color-two;
$primary-three: $color-three;

$card_background_1: $color_three;
$card_text_1: $color_four;

$color-play-button-agenda: rgb(35, 94, 231);
$highlighted-agenda: #282828bf;
$highlighted-agenda-text-color: $color-three;
$color-hover: $color-one;
$color-hover-text: $color-three;

$color-link: $color-two;
$color-link-active: $color-one;

$color-name:#384150;
$color-black: $color_five;
$color-black-75: #282828bf;
$color-gradient: $color_two;
$color-blue: $color_two;
$color-black-50-rgba: rgba(40, 40, 40, 0.08);
$color-black-50: #28282880;
$color-pill-background: #f0f0f0ff;
$color-black-8: #28282814;
$color-grey-shade: #a1a9bfff;

$color-background-1: #F8F8F8;
$color-background-2: #f5f5f5;
$color-background-3: #F0F0F0;
$color-background-4: #FAFBFC;

$color-background-sidebar-main: $color-background-1;
$color-background-title-bar: $color-background-1;
$color-background-sidebar: $color-background-2;



$color-message-color: #545e67ff;
$color-message: $color_five;
$color-contrast: #6b6f7aff;
$color-name-color: #384150ff;
$color-light-grey-elements: #87878714;
$color-white: #ffffffff;
$color-background-container: $color_three;

$color-play-button: #235EE714;
$color-silver: #A1A9BF;
$color-line-between-desc: #C4C4C4;
$color-White-Smoke:#F8F8F8;
$medium-light-shade-of-blue:#4c6fff;
$green:green;
$color-approx-islamic-green:#12B76A;
$color-cyan-blue:#1976D2;
$early-dew: #44b700;
$approx-white-smoke: #F1F1F1;
$color-yellow: yellow;


// Main background color
$color-background: $color-three;
$color-text-main: $color-one;
$color-text: $color-two;

//Global
$arrow-color: $primary-two;
$inactive : #6B6F7A;
$bgColor-event:$color-white;

// status online
$bgColor-status-online:$color-white;
$color-status-online:$early-dew;
$color-status-offline:$color-silver;

//SideBar
$color-selected-icon: $color-one;
$color-sidebar-icon: $color-two;

//Agenda
$bgColor-circle-active-day: $color-blue;
$bgColor-no-avatar-svg: $color-White-Smoke;
//$bgColor-event-agenda-calendar:$color-background;
$color-background-sidebar-date: $color-background-4;
//$color-cards-background: $color-background-2;
$color-background-cards: $color-background-1;

$color-days-name: $color-two;
$color-date-number: $color-white;
$color-circle-active-day: $color-text-main;
$color-border-active-agenda: $color-black-50-rgba;
$color-agenda-speakers-placeholder-text-and-avatar-text: $color-White-Smoke;
$color-chair-agenda-text:$color-black-50;
$color-agenda-play-button-not-active:$color-play-button-agenda;
$color-agenda-play-button-active: $color-white;
$color-agenda-play-button-active-arrow:$color-white;
$color-agenda-play-button-not-active-arrow:$color-black;
$color-more-agenda-not-active:$color-black;
$color-more-agenda-active:$color-white;

//Participant
$bgColor-search-container:#f3f4f8ff;
$bgColor-input-participant:$bgColor-search-container;
$bgColor-filter-participant:$bgColor-search-container;
$bgColor-filter-full-screen:$bgColor-search-container;
$bgColor-hover-filter-menu:$medium-light-shade-of-blue;

$color-email-participant-cart:rgb(239, 240, 243);
$color-hover-filter-menu:$medium-light-shade-of-blue;
$color-filter-menu-check-icon:$green;

//Presence
$color-in-person: $color-approx-islamic-green;
$color-on-site:$medium-light-shade-of-blue;

//Inbox
$bgColor-tab-indicator: $color-two;
$color-background-sidebar-tabs: $color-background-4;

//Chats
$bgColor-input: $color-background-cards;
$bgColor-new-msg:$color-cyan-blue;
$bgColor-form:$color-white;
$bgColor-my-msg: $color-two;
$bgColor-not-my-msg: $color-background-cards;
$bgColor-admin-msg: $color-one;


$color-new-msg:$color-white;
$time-color : $color-contrast;
$color-my-msg: $color-three;
$color-not-my-msg: $color-two;
$color-admin-msg: $color-three;

$color-name-chats : $color-name-color;
$color-time-chats: $color-contrast;
$color-send-button: #1976d2;

//Polls
$bgColor-live-poll: $color-background-cards;

$color-live-poll: $color-black;
$color-form-control: $primary-one;
$color-answered-time:#787e83;
$color-answered:$color-white;

//Question
$color-delete-popover:red;
$color-question-inside: $color-white;
$bgColor-question: $color-white;

//Poster
$bgColor-posters: $color-background-cards;
$bgColor-navigate-posters-details-active:$color-background;

//Event details
$bgColor-agenda-detail-desc-watch-btn:$color-play-button;
$bgColor-agenda-detail-desc-watch-btn-hover:$color-play-button;

$color-agenda-detail-desc-watch-btn:$color-blue;
$color-agenda-detai-viewall:$color-blue;

//Poster details
$bgColor-poster-clinic:$color-black-50-rgba;
$bgColor-video-desc-container:$color-white;
$bgColor-asc-question-poster:rgba(161, 169, 191, 0.2);
$bgColor-hls-player-poster-container:$color-black;

$color-mobile-desc-border:$color-black-50-rgba;

//Home page events
$bgColor-events-tags:$color-white;
$bgColor-event-load-more-button :$color-gradient;

$color-events-month:$color-blue;
$color-events-day-line:$color-grey-shade;
$color-events-day:$color-black;
$color-events-time:$color-black;
$color-events-tags-svg:#0000005c;
$color-events-tags-svg-hover :rgb(28, 27, 27);
$color-events-load-more-button :$color-white;
$event-event-heading:$color-black;
$color-speaker-dp-hover:rgba(0, 0, 0, 0.2);

//@mixin text-style-navbar-heading{
//  font-size: 22px;
//  font-family: "Helvetica Neue";
//  font-weight: 700;
//  font-style: normal;
//  text-decoration: none;
//  text-transform: none;
//}
//
//@mixin text-style-chat-username {
//  font-size: 16px;
//  font-family: "Helvetica Neue";
//  font-weight: 500;
//  font-style: normal;
//  text-decoration: none;
//  text-transform: none;
//}
//
//@mixin text-style-messages-comments {
//  font-size: 16px;
//  font-family: "Helvetica Neue";
//  font-weight: 400;
//  font-style: normal;
//  line-height: 175%;
//  text-decoration: none;
//  text-transform: none;
//}
//
//@mixin text-style-navbar-heading {
//  font-size: 22px;
//  font-family: "Helvetica Neue";
//  font-weight: 700;
//  font-style: normal;
//  text-decoration: none;
//  text-transform: none;
//}
//
//@mixin text-style-chat-username {
//  font-size: 16px;
//  font-family: "Helvetica Neue";
//  font-weight: 500;
//  font-style: normal;
//  text-decoration: none;
//  text-transform: none;
//}
//
//@mixin text-style-messages-comments {
//  font-size: 16px;
//  font-family: "Helvetica Neue";
//  font-weight: 400;
//  font-style: normal;
//  line-height: 175%;
//  text-decoration: none;
//  text-transform: none;
//}


//color variables
//$whiteColor : #fff;
//$button-gradient: linear-gradient(
//  90.98deg,
//  #ff68c3 0%,
//  #ff7474 29.69%,
//  #5771f9 100%
//);
$ratio-gradient: linear-gradient(90deg, $color_three 0%, $color-one 100%);
$child-titleColor: $color-background;


//$wtt-red: #d72864;
//$wtt-dark-red: #8c195f;
//$gallus-blue: rgb(76, 111, 255);
//$gallus-silver:  #F3F4F8;

$text-color-title: $color-black;

//$child-titleColor : $wtt-dark-red;
$child-titleTextColor: black;
//$agenda-color: $gallus-silver;
$poll-color: $color-blue;
$button-disabled-color: rgba(40, 40, 40, 0.08);


$button-enabled-color: $color-two;
$button-enabled-text-color: $color-three;
$button-disabled-color: $color-background-cards;
$button-disabled-text-color: $color-background-cards;


$font-main: UniversLTPro-45Light, Univers, univers, Helvetica, helvetica, Arial, arial, 黑体, "Hei Ti", heiti, 微软雅黑, "Microsoft YaHei", 宋体, SimSun, sans-serif;
$font-second: UniversLTPro-45Light, Univers, univers, Helvetica, helvetica, Arial, arial, 黑体, "Hei Ti", heiti, 微软雅黑, "Microsoft YaHei", 宋体, SimSun, sans-serif;
$font-third: UniversLTPro-45Light, Univers, univers, Helvetica, helvetica, Arial, arial, 黑体, "Hei Ti", heiti, 微软雅黑, "Microsoft YaHei", 宋体, SimSun, sans-serif;
$font-desc: 'Helvetica',sans-serif;
$font-title: UniversLTPro-65Bold, Univers, univers, Helvetica, helvetica, Arial, arial, 黑体, "Hei Ti", heiti, 微软雅黑, "Microsoft YaHei", 宋体, SimSun, sans-serif;
$font-placeholder: 'Outfit',sans-serif;


$primary_main: #8c195f;
$primary_light: #bf4e8c;
$primary_dark: #5a0035;

$secondary_main: #d72864;
$secondary_light: #ff6391;
$secondary_dark: #a0003a;

$colors: (
        highlighted_agenda_text_color : $highlighted-agenda-text-color,
        color_black: $color-black,
        color_white: $color-white,
        color_silver_icons: $color-silver,
);

:export {
  //primary: $wtt-dark-red;
  //secondary: $wtt-red;


  secondary_main: $secondary_main;
  secondary_light: $secondary_light;
  secondary_dark: $secondary_dark;

  primary_one: $color-one;
  primary_two: $color-two;
  primary_three: $color-three;

  primary_main: $color-one;
  primary_light: $color-three;
  primary_dark: $color-two;
  primary_contrast_text: $color-three;

  button_enabled_color: $button-enabled-color;
  button_enabled_text_color: $button-enabled-text-color;
  button_disabled_color: $button-disabled-color;
  button_disabled_text_color: $button-disabled-text-color;

  highlighted_agenda_text_color: $highlighted-agenda-text-color;
  highlighted_agenda: $highlighted-agenda;
  color_hover: $color-hover;
  color_hover_text: $color-hover-text;
  color_black: $color-black;
  color_black-75: $color-black-75;
  color_black_50_rgba: $color-black-50-rgba;
  color_background: $color-background;
  color_gradient: $color-gradient;
  color_blue: $color-blue;
  color_black_50: $color-black-50;
  color_pill_background: $color-pill-background;
  color_black_8: $color-black-8;
  color_grey_shade: $color-grey-shade;
  color_background_2: $color-background-2;
  color_message_color: $color-message-color;
  color_message: $color-message;
  color_contrast: $color-contrast;
  color_name_color: $color-name-color;
  color_light_grey_elements: $color-light-grey-elements;
  color_white: $color-white;
  color_background_container: $color-background-container;
  color_cards_background: $color-background-cards;
  color_silver_icons: $color-silver;
  color_play_button: $color-play-button-agenda;
  color_play_button_bg: $color-play-button;
  //button_gradient: $button-gradient;
  color_sidebar_icon: $color-sidebar-icon;
  color_selected_icon: $color-selected-icon;
  color_agenda_play_button_not_active: $color-agenda-play-button-not-active;
  color_agenda_play_button_not_active_arrow: $color-agenda-play-button-not-active-arrow;
  color_agenda_play_button_active: $color-agenda-play-button-active;
  color_agenda_play_button_active_arrow: $color-agenda-play-button-active-arrow;

  //Participant
  bgColor_input_participant: $bgColor-input-participant;

  //Presence
  color_in_person: $color-in-person;
  color_on_site: $color-on-site;

  //Inbox
  bgColor_tab_indicator: $bgColor-tab-indicator;

  //Polls
  color_form_control: $color-form-control;

  // fonts
  font_main: $font-main;
  font_second: $font-second;
  font_third: $font-third;
  //font_main_temporary: "Helvetica Neue", sans-serif;
  color_text: $color-text;
}


