@font-face {
    font-family: UniversLTPro-45Light;
    src: url("3692f3_0_0eot-2.eot");
    src: url("3692f3_0_0eot-2.eot?#iefix") format("embedded-opentype"),url("3692f3_0_0woff2.woff2") format("woff2"),url("3692f3_0_0woff-2.woff") format("woff"),url("3692f3_0_0ttf-2.ttf") format("truetype");
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: UniversLTPro-65Bold;
    src: url("3692f3_1_0eot.eot");
    src: url("3692f3_1_0eot.eot?#iefix") format("embedded-opentype"),url("3692f3_1_0woff2.woff2") format("woff2"),url("3692f3_1_0woff-2.woff") format("woff"),url("3692f3_1_0ttf-2.ttf") format("truetype");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: sfs-iconfont;
    src: url("sfs-iconfont.eot");
    src: url("sfs-iconfont.eot?#iefix") format("eot"),url("sfs-iconfont.woff2") format("woff2"),url("sfs-iconfont.woff") format("woff"),url("sfs-iconfont.ttf") format("truetype"),url("sfs-iconfont.svg#sfs-iconfont") format("svg")
}
