@import "../../App";
$signin-page-background: #f8f8f8;

h3 {
  height: 0;
}

.sign-up-success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 90px);
  width: 100vw;
  background-color: $signin-page-background;

  & > div {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    text-align: center;
    align-items: center;
    gap: 12px;
    padding: 48px;
    padding-bottom: 72px;
    background-color: $color-white;
  }

  h2 {
    @include font-style($font-second, 700, 36px, 43px);
  }
}

.sign-up-main {
  background-color: $signin-page-background;
  text-align: center;
  display: flex;
  height: calc(100vh - 90px);
  width: 100%;
  justify-content: center;

  form {
    width: 30%;
    margin: auto;
  }

  .forgot-pass-form {
    text-align: center;
    @include flex(center, center);
    padding: 48px 48px 72px 48px;
    flex-direction: column;
    background-color: $color-white;

    .welcome-msg {
      @include font-style($font-second, 700, 36px, 43px);
    }

    .desc {
      @include font-style($font-main, 400, 20px, 20px);
      padding-top: 23px;
      color: $color-black;
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiButton-contained {
    @include button;
    padding: 13px 0;
  }

  .sign-up-form-success {
    width: 25%;
    margin: auto;
    text-align: center;
    @include flex(center, center);
    padding: 48px 48px 72px 48px;
    flex-direction: column;
    background-color: $color-white;

    .welcome-msg {
      @include font-style($font-second, 700, 36px, 43px);
    }

    .desc {
      @include font-style($font-main, 400, 16px, 24px);
      padding-top: 23px;
      color: $color-black;
    }
  }

  .forgot-pass-form-success {
    width: 25%;
    margin: auto;
    text-align: center;
    @include flex(center, center);
    padding: 48px 48px 72px 48px;
    flex-direction: column;
    background-color: $color-white;

    .welcome-msg {
      @include font-style($font-second, 700, 36px, 43px);
    }

    .desc {
      @include font-style($font-main, 400, 16px, 24px);
      padding-top: 23px;
      color: $color-black;
    }
  }
}

.sign-up-form {
  text-align: center;
  background-color: $color-white;
  @include flex(center, center);
  flex-direction: column;
  padding: 48px 48px 72px 48px;

  .welcome-msg {
    @include font-style($font-second, 700, 36px, 43px);
  }

  .desc {
    @include font-style($font-main, 400, 20px, 20px);
    padding-top: 23px;
    color: $color-black;
  }
}

.sign-up-form .MuiTextField-root {
  width: 100%;
}

.sign-up-form .MuiButton-contained {
  @include button;
  width: 100%;
  padding: 13px 0;
}

.sign-up-option {
  @include flex(space-evenly, center);
  width: 100%;
}

.no-account {
  width: 100%;
  @include font-style($font-main, 400, 16px, 16px);
  margin-top: 36px;
  text-align: center;
}

.no-account span {
  color: #5771f9;
  cursor: pointer;
}

@media (min-width: 1024px) and (max-width: 1768px) {
  .sign-up-main {
    form {
      width: 35%;
    }

    .forgot-pass-form {
      .welcome-msg {
        @include font-style($font-second, 700, 32px, 43px);
      }

      .desc {
        @include font-style($font-main, 400, 16px, 20px);
        padding-top: 10px;
      }
    }

    .forgot-pass-form-success {
      width: 35%;
      padding: 36px 48px 64px 48px;

      .welcome-msg {
        @include font-style($font-second, 700, 32px, 43px);
      }

      .desc {
        @include font-style($font-main, 400, 16px, 24px);
      }
    }

    .sign-up-form-success {
      width: 35%;
      padding: 36px 48px 64px 48px;

      .welcome-msg {
        @include font-style($font-second, 700, 32px, 43px);
      }

      .desc {
        @include font-style($font-main, 400, 16px, 24px);
      }
    }
  }

  .sign-up-form {
    padding: 36px 48px 64px 48px;

    .welcome-msg {
      @include font-style($font-second, 700, 32px, 43px);
    }

    .desc {
      @include font-style($font-main, 400, 16px, 20px);
      padding-top: 10px;
    }
  }
}

//Tablet Responsive

@media (min-width: 768px) and (max-width: 1023px) {
  .sign-up-main {
    width: 100%;
    .forgot-pass-form {
      width: 55%;
      margin: auto;
    }

    .forgot-pass-form-success {
      width: 55%;
      padding: 36px 48px 64px 48px;

      .welcome-msg {
        @include font-style($font-second, 700, 32px, 43px);
      }

      .desc {
        @include font-style($font-main, 400, 16px, 24px);
      }
    }

    .sign-up-form-success {
      width: 55%;
      padding: 36px 48px 64px 48px;

      .welcome-msg {
        @include font-style($font-second, 700, 32px, 43px);
      }

      .desc {
        @include font-style($font-main, 400, 16px, 24px);
      }
    }
  }
}

//Mobile Responsive
@media (max-width: 767px) {
  .sign-up-main {
    .forgot-pass-form {
      padding: 48px 25px 64px 25px;

      .welcome-msg {
        @include font-style($font-second, 700, 28px, 43px);
      }

      .desc {
        @include font-style($font-main, 400, 18px, 28px);
        // padding-top: 23px;
        color: $color-black;
      }
    }

    .forgot-pass-form-success {
      width: 75%;
      padding: 48px 24px 64px 24px;

      .welcome-msg {
        @include font-style($font-second, 700, 28px, 43px);
      }
    }
  }

  .sign-up-main form {
    width: 90% !important;
  }

  .sign-up-option {
    width: 100%;
  }

  .sign-up-form .MuiTextField-root {
    width: 100%;
  }

  .sign-up-form .MuiButtonBase-root {
    width: 100%;
  }

  .sign-up-main .forgot-pass-form .MuiFormControl-root {
    width: 100%;
  }

  .sign-up-main .MuiButtonBase-root {
    width: 100%;
  }
}
